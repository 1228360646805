
import storage from 'redux-persist/lib/storage';
import { createMuiTheme } from "@material-ui/core";

// 永続化の設定
export const persistConfig = {
	key: 'root', // Storageに保存されるキー名を指定する
	storage, // 保存先としてlocalStorageがここで設定される
	whitelist: [ 'logined', 'liffUrlQueryParam', 'liffPath' ] // Stateは`todos`のみStorageに保存する
	// blacklist: ['visibilityFilter'] // `visibilityFilter`は保存しない
};

/**
 * firebase
 */

export const firebaseConfig =
	process.env.REACT_APP_ENV === 'production'
		? {
      apiKey: "AIzaSyAK5zt-GYEj-kIR_Bit0E5YSxGP64By0kI",
      authDomain: "cookon-prod.firebaseapp.com",
      databaseURL: "https://cookon-prod.firebaseio.com",
      projectId: "cookon-prod",
      storageBucket: "cookon-prod.appspot.com",
      messagingSenderId: "818050183509",
      appId: "1:818050183509:web:98dae30c4c6c7ecbcfffdc",
      measurementId: "G-QRJDYN4P8G"
    }
		: {
      apiKey: "AIzaSyBD_P-KANAARZAIMSZ9n2txqD6UlPTQjq0",
      authDomain: "cookon-stg.firebaseapp.com",
      databaseURL: "https://cookon-stg.firebaseio.com",
      projectId: "cookon-stg",
      storageBucket: "cookon-stg.appspot.com",
      messagingSenderId: "1069590226917",
      appId: "1:1069590226917:web:a47ef9538a79fd01b0710c",
      measurementId: "G-VV2SGQP2HB"
    };
/**
 * material ui
 */


export const theme = createMuiTheme({
  palette:{
    brand: '#252B43',
    primary:{
      main:'#FF9B5C',
    },
    secondary: {
      main: '#FF9B5C',
      light: '#F6C06F',
    },
    black: {
      textColor: '#2B2B2B',
    },
    gray: {
      gray01: `#F2F4F8`,
      gray02: '#DDDDDD',
      gray03: '#C3C3C3',
      gray04: '#B5B5B5',
      gray05: '#8F8F8F',
      gray06: '#707070',
    }
  },
  typography: {
    textColor: '#2B2B2B',
    fontSize: 14,
    fontFamily: [
      'Noto Sans JP',
      'sans-serif',
    ].join(','),
  },
  drawerWidth:'240px',
  tableHeight:'440px',
  gutter:{pc:'64px',sp:'56px'}
});

/*
 * LINE
 */
export const lineLoginConfig =
	window.location.hostname === 'localhost'
		? {
				base_url: 'https://access.line.me/oauth2/v2.1/authorize',
				response_type: 'code',
				client_id: '1654395207',
				redirect_uri: 'http://localhost:3000/auth',
				scope: 'openid%20profile&bot_prompt=aggressive'
			}
		: {
				base_url: 'https://access.line.me/oauth2/v2.1/authorize',
				response_type: 'code',
				client_id: '1654395207',
				redirect_uri:
					process.env.REACT_APP_ENV === 'production'
						? 'https://consumer.cookon.app/auth'
						: 'https://cookon-consumer-stg.web.app/auth',
				scope: 'openid%20profile&bot_prompt=aggressive'
      };

  export const redirect_uri =
  window.location.hostname === 'localhost'
    ? 'http://localhost:3000/auth'
    : process.env.REACT_APP_ENV === 'production'
      ? 'https://consumer.cookon.app/auth'
      : 'https://cookon-consumer-stg.web.app/auth';

/**
 * stripe
 */
export const stripeApiKey =
	process.env.REACT_APP_ENV ===  'production'
		? process.env.REACT_APP_STRIPE_PK_LIVE
    : process.env.REACT_APP_STRIPE_PK_TEST;

/**
 * algolia
 */
export const algoliaSearchKey = process.env.REACT_APP_ALGOLIA_SEARCH_KEY;
export const algoliaId = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
export const algoliaIndexId = window.location.hostname === 'localhost' ? 'Cookon-stg' : process.env.REACT_APP_ENV === 'production' ? 'Cookon-prod' : 'Cookon-stg';

/**
 * GA
 */
export const gaTrackingID =  process.env.REACT_APP_GA;
export const consumerUrl = 	process.env.REACT_APP_ENV ===  'production' ? 'https://consumer.cookon.app' : 'https://cookon-consumer-stg.web.app';
export const providerUrl = 	process.env.REACT_APP_ENV ===  'production' ? 'https://provider.cookon.app' : 'https://cookon-provider-stg.web.app';

export const liffId = (window.location.hostname === 'localhost' || window.location.hostname ==='d8ed-2404-7a80-ae0-f700-3df9-74ec-b701-a774.ngrok-free.app') ? '1654395207-WlqYlbmG' :
 process.env.REACT_APP_ENV === 'production'
  ? '1654395207-A0p4R7P0'
  : '1654395207-YXKkdJ3W';