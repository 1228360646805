import React, { useEffect, useRef, Suspense } from 'react';
import './App.css';
import { pages } from './Router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { theme } from './config';
import FirebaseToRedux from './firebase/FirebaseToRedux';
import ReactGA from 'react-ga';
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from '@material-ui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { AppFrame } from './components/templates/AppFrame';
import { MenuList, IconMenu } from './components/molecules/MenuList';
import { AppLogo } from './components/atoms/AppLogo';
import MovieIcon from '@material-ui/icons/Movie';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSelector } from 'react-redux';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { useDispatch } from "react-redux";
import { setLiffUrlQueryParam } from './redux/action_reducers/liffUrlQueryParam';
import liff from '@line/liff';
import { liffId } from './config';
import { setLiffPath } from './redux/action_reducers/liffPath';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BackDropProgress } from './components/atoms/BackDropProgress';


const muiTheme = createMuiTheme(theme);

const myMenus = () => {
  return [
    {
      icon: <AccountCircleIcon />,
      text: 'マイページ',
      path: `/my`,
    },
    {
      icon: <ExitToAppIcon />,
      text: 'ログアウト',
      path: '/logOut'
    },
  ];
};

const menus = () => {
  return [
    {
      path: `/search`,
      icon: <MovieIcon style={{ color: 'white' }} className={{ width: '16px', height: '16px' }} />,
      text: 'さがす',
    },
    {
      path: `/my`,
      icon: <HomeOutlinedIcon style={{ color: 'white' }} className={{ width: '16px', height: '16px' }} />,
      text: 'マイページ',
    },
    {
      path: `/edit-profile`,
      icon: <SettingsIcon style={{ color: 'white' }} className={{ width: '16px', height: '16px' }} />,
      text: '設定',
    },
    {
      path: `/logout`,
      icon: <ExitToAppIcon style={{ color: 'white' }} className={{ width: '16px', height: '16px' }} />,
      text: 'ログアウト',
    },
  ];
};


function App() {
  const logined = useSelector(state => state.logined);
  const router = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    router.current.history.listen((location) => {
      const pathname = location.pathname;
      ReactGA.set({ page: pathname });
      ReactGA.pageview(pathname);
    });
  })
  useEffect(() => {
    liff.init({ liffId: liffId });
    const liff_url = window.location.search;
    //const liffUrlRegEx = liff_url.match(/^(https?:\/\/.+)\/(.+)\/(.+)\/(.+)$/);
    //if (!liffUrlRegEx) return;
    //if (liffUrlRegEx[1] === 'https://liff.line.me') {
    const queryParams = liff_url.split("?");

    if(window.location.pathname !== '/'){
      let locationPath = window.location.pathname;
      if(locationPath.charAt(locationPath.length-1 ) == "/") {
        locationPath = locationPath.slice(0, -1)
    }
      dispatch(setLiffPath(locationPath));
    }
    if (queryParams?.length >= 2) {
      const listQueryParam = [];
      const keyWords = [/content/, /singleItem/, /providerId/, /providers/, /couponId/];
      for (let i = 0; i < queryParams.length; i++) {
        for (let j = 0; j < keyWords.length; j++) {
          if (queryParams[i].match(keyWords[j])) {
            listQueryParam.push(queryParams[i])
            break;
          }
        }
      }
      if (listQueryParam.length > 0) {
        dispatch(setLiffUrlQueryParam(listQueryParam));
      }
    }
    //}
  }, []);
  return (
    <div className="App" style={{ textAlign: 'left' }}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <StyledThemeProvider theme={muiTheme}>
            <FirebaseToRedux />
            <Router ref={router}>
              <AppFrame
                logined={logined}
                appLogo={<AppLogo />}
                menus={<MenuList logined={logined} menus={menus} />}
                iconMenu={<IconMenu logined={logined} myMenus={myMenus} />}
              >
                {pages.map((item) => (
                  <Route
                    key={item.path}
                    exact
                    path={item.path}
                    component={() =><Suspense fallback={<LinearProgress />}>{item.component}</Suspense>} />
                ))}
              </AppFrame>
            </Router>
          </StyledThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </div>
  );
}

export default App;
